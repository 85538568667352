import React from 'react'
import '../styles/StatusItem.css'

class StatusItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className='StatusItem'>
                <div className='StatusItem-header'>
                    {this.props.header}
                </div>
                <div className='StatusItem-content'>
                    <div className='columns is-mobile'>
                        <div className='column is-4'>
                            <div className='StatusItem-title'>
                                Status
                            </div>
                        </div>
                        <div className='column is-8'>
                            <div className='StatusItem-status'>
                                {this.props.value}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatusItem
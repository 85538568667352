import React from 'react'
import '../styles/ProductItem.css'
import ReactiveButton from '../components/ReactiveButton'

class ProductItem extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            priceClass: null
        }
    }

    setDefaultPrice(){
        this.setState({
            priceClass: 'ProductItem-Price',
            oldPriceClass: 'hidden'
        })
    }

    setDiscountPrice(){
        this.setState({
            priceClass: 'ProductItem-Price-Discount',
            oldPriceClass: 'ProductItem-Price-Old'
        })
    }

    componentDidMount(){
        // check props 
        if (this.props.product.old_price){
            if (this.props.product.old_price == this.props.product.price){
                this.setDefaultPrice()
            }
            else {
                this.setDiscountPrice()
            }
            
        }
        else {
            this.setDefaultPrice()
        }
    }


    render(){
        return (
            <div className='ProductItem'>
                <div className='columns is-gapless is-mobile'>
                    <div className='column is-4'>
                            <img className='ProductItem-Image' src={this.props.product.image} 
                                                                onClick={this.props.onClick}/>
                    </div>
                    <div className='column is-8'>
                       <div className='ProductItem-Name'>
                           {this.props.product.name}
                       </div>
                       <div className='ProductItem-Description'>
                            {this.props.product.variant } {this.props.product.unit}
                       </div> 
    
                       <div className='columns is-gapless is-mobile'>
                            <div className='column is-5'>
                                <span className={this.state.priceClass}> Ksh {this.props.product.price} </span>
                                <div className={this.state.oldPriceClass}> Ksh {this.props.product.old_price} </div>
                            </div>
                            <div className='column is-8'>
                               <ReactiveButton value='Add' product={this.props.product} onRefresh={this.props.onRefresh} active/>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductItem
import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'

import '../styles/Affiliates.css'
import NoAffiliateIcon from '../images/noaffiliate.svg'
import DbService from '../services/db'

class Affiliates extends React.Component {

    constructor(props){
        super(props)
        this.goBack = this.goBack.bind(this)
        this.dbService = new DbService()
    }

    navigate(link){
        this.props.history.push(link)
    }

    goBack(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    render(){
        let link = this.dbService.getAffiliateLink()
        
        if (link){
            let relativeLink = link.replace(/^(?:\/\/|[^/]+)*\//, '/')
            return(
                <div className='Affiliates'>
                    <StickyBarTop title='My Affiliate Link' onBackPress={this.goBack}/>
                    <div className='Affiliates-Content'>
                        <div className='Affiliates-Text'>
                                Your Affiliate Link
                            </div>
                        <div className='Affiliates-Link'>
                            {link}
                        </div>
                    </div>
                    <StickyBarBottom value='Order with my link' onClick={ () => this.navigate(relativeLink)}/>
                </div>
            )
        }
        else {
            return (
                <div className='Affiliates'>
                    <StickyBarTop title='My Affiliate Link' onBackPress={this.goBack}/> 
                    <div className='Affiliates-Content'>
                        <div className='Affiliates-Image'>
                            <img src={NoAffiliateIcon}/>
                        </div>
                        <div className='Affiliates-Text'>
                            You haven't created an affiliate link yet
                        </div>
                        <div className='Affiliates-Button'>
                                <button onClick={ () => this.navigate('/affiliates/new')}>+ Create One</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Affiliates
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route, Link,  BrowserRouter as Router } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import './index.css';
//import App from './App';
import Login from './components/Login'
import Verify from './components/Verify'
import Home from './pages/Home'
import Products from './pages/Products'
import ProductDetails from './pages/ProductDetails'
import Basket from './pages/Basket'
import Brands from './pages/Brands'
import Orders from './pages/Orders'
import Checkout from './pages/Checkout'
import Account from './pages/Account'
import Feed from './pages/Feed'
import OrderDetails from './pages/OrderDetails'
import Final from './pages/Final'
import Favorites from './pages/Favorites'
import * as serviceWorker from './serviceWorker'
// redux 
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './store/reducer'
import Address from './pages/Address';
import Addresses from './pages/Addresses'
import Contacts from './pages/Contacts'
import Contact from './pages/Contact'
import Fallback from './pages/Fallback'
import Offline from './pages/Offline'
import Redeem from './pages/Redeem';
import Affiliate from './pages/Affiliate'
import Affiliates from './pages/Affiliates'
import Subscriptions from './pages/Subscriptions'


const store = createStore(reducer)

// mobile && online route
function RouteX({ component: Component, ...rest }) {
  var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  var isOnline = navigator.onLine;
    return (
      <Route
      {...rest}
      render={props =>

        isOnline ? [
          (
            isMobile ? <Component {...props} />
            : <Redirect to={{ pathname: "/desktop", }} />
          )
        ]:  <Redirect to={{pathname: "/offline",}} />
    }
    />
    )
  }

//

const routing = (
      <Router>
        <div>
          <RouteX exact path="/" component={Home} /> 
          <RouteX path="/go/:code" component={Home} />
          <RouteX path="/products/:id" component={ProductDetails} />
          <RouteX path="/categories/:name" component={Products} />
          <RouteX exact path="/orders" component={Orders} />
          <RouteX path="/orders/:id" component={OrderDetails} />
          <RouteX path="/basket" component={Basket} />
          <RouteX exact path="/brands" component={Brands} />
          <RouteX exact path="/brands/:id" component={Products} />
          <RouteX path="/account" component={Account} />
          <RouteX path="/checkout" component={Checkout} />
          <RouteX path="/feed" component={Feed} />
          <RouteX path="/redeem" component={Redeem} />
          <RouteX path="/summary/:id" component={Final} />
          <RouteX path="/favorites" component={Favorites} />
          <RouteX path="/addresses" component={Addresses} />
          <RouteX path="/address/:id" component={Address} />
          <RouteX path="/contacts" component={Contacts} />
          <RouteX path="/contact/:id" component={Contact} />
          <RouteX path="/affiliates/new" component={Affiliate} />
          <RouteX path="/affiliates/me" component={Affiliates} />
          <Route path="/desktop" component={Fallback} />
          <Route path="/offline" component={Offline} />
          <RouteX path='/subscriptions/:id' component={Subscriptions}/> 
        </div>     
      </Router>
  )

  ReactDOM.render(routing, document.getElementById('root'));

serviceWorker.register();
import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'
import StatusItem from '../components/StatusItem'
import OrderUnitItem from '../components/OrderUnitItem'
import Receipt from '../components/Receipt'
import '../styles/OrderDetails.css'
import ApiService from '../services/api'
import DbService from '../services/db'

class OrderDetails extends React.Component{
    
    constructor(props){
        super(props)
        this.state = {
            rows: null,
            total: 0,
            orderStatus: null,
            delivery_fees: null,
            visibility: 'hidden'
        }

        this.apiService = new ApiService()
        this.dbService = new DbService()
        this.orderDetailsRows = null
        this.orderId = this.props.match.params.id
        this.handleClick = this.handleClick.bind(this)
        this.goBack = this.goBack.bind(this)
        
    }

    componentWillMount(){
        this.apiService.getOrderDetails(this.orderId).then( (res) => {
            this.setState({
                rows: res,
                reference: res.reference,
                total: res.total,
                orderStatus:  res.status, 
                delivery_fees: res.delivery_fees,
                visibility: 'visible'
            })
            console.log(res)
        }).catch( (err) => {
            console.error(err)
        })
    }

    // reorder
    handleClick(e){
        e.preventDefault()
        this.dbService.populate(this.state.rows.units)
        this.props.history.push('/basket')
    }
    
    goBack(e){
        this.props.history.goBack()
    }

    render(){
        let rows = this.state.rows
        //
        let style = {
            visibility: this.state.visibility
        }

        //

        if (rows){
            let units = rows.units
            console.log(units)
            this.orderDetailsRows = units.map( (value, index) => {
                return (
                    <OrderUnitItem name={value.name} 
                                      image={value.image} 
                                      price={value.price}
                                      qty={value.quantity}
                                      key={index}/> 
                );
            } )
        }

        //
        let receiptPayload = {
            value: this.state.total,
            delivery: this.state.delivery_fees
        }

        return (
            <div className='OrderDetails'>
                <StickyBarTop title='Order Details' onBackPress={this.goBack}/>
                <div className='OrderDetails-header' style={style}>
                    Basket
                </div>
                <div className='OrderDetails-content' style={style}>
                    { this.orderDetailsRows }
                </div>
                <div className='OrderDetails-header reference' style={style}>
                    Order Reference
                </div>
                <div className='OrderDetails-Reference' style={style}>
                    {this.state.reference}
                </div>
                <div style={style} >
                    <StatusItem header='Order Status' value={this.state.orderStatus} /> 
                    <Receipt header='Receipt' payload={receiptPayload}/>
                    <StickyBarBottom value='Order Again' onClick={this.handleClick}/>
                </div>
                
    
            </div>
        );
    }
    
}

export default OrderDetails
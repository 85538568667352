import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import '../styles/ContactItem.css'
import { Link } from 'react-router-dom'

class ContactItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='ContactItem'>
                <Link to={this.props.link} className='ContactItem-Link'>
                    
                    <div className='ContactItem-content'>
                        <div className='columns is-mobile'>
                            <div className='column is-1'>
                                <div className='ContactItem-icon'>
                                    <FontAwesomeIcon icon={faUserAlt} />
                                </div>
                            </div>
                            <div className='column is-9'>
                                <div className='ContactItem-name'>
                                    {this.props.contact.name}
                                </div>
                                <div className='ContactItem-number'>
                                    {this.props.contact.number}
                                </div>
                            </div>
                            <div className='column is-2'>
                            <div className='ContactItem-icon'>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            
        )
    }
}

export default ContactItem
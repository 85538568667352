import React from 'react'
import '../styles/EmptyOrderItem.css'

class EmptyOrderItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='EmptyOrderItem'>
                <div className='EmptyOrderItem-text shine'>
                </div>    
            </div>
        );
    }
}

export default EmptyOrderItem
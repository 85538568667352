import React from 'react'
import '../styles/BrandItem.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

class BrandItem extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            className: 'BrandItem'
        }
    }

    componentDidMount(){
        if (this.props.empty){
            this.setState({
                className: 'BrandItem-Empty'
            })
        }
    }


    render(){
        return(
            <div className={this.state.className}>
                <div className='fade-in-bck'>
                    <Link to={'/brands/' +  this.props.id}>
                        <div className='columns is-mobile'>
                            <div className='column is-10'>
                                <div className={this.state.className + '-Name'}>
                                    {this.props.name}
                                </div>
                            </div>
                            <div className='column is-2'>
                                <div className='OrderItem-Arrow'>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        );
    }
}

export default BrandItem
import React from 'react'
import '../styles/ProductDetails.css'
import StickyBarTop from '../components/StickyBarTop'
import ReactiveButton from '../components/ReactiveButton'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
import DbService from '../services/db'

class ProductDetails extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            product: { }
        }

        this.dbService = new DbService()
        this._collection = 'productDetails'
        this.goBack = this.goBack.bind(this)
    }

    componentDidMount() {
        this.setState({
            product: this.dbService.getCache(this._collection)
        })
    }

    goBack(e){
        this.props.history.goBack()
    }

    render(){
        return (
            <div className='ProductDetails'>
                <div className='ProductDetails-header'>
                    <StickyBarTop title='Product Details' onBackPress={this.goBack}/>
                </div>
                <div className='ProductDetails-content'>
                    <div className='ProductDetails-Image'>
                        <img src={this.state.product.image} alt=""/>
                    </div>
                    <div className='ProductDetails-Price'>
                        <span>
                            Ksh. {this.state.product.price}
                        </span>
                    </div>
                    <div className='ProductDetails-Name'>
                        <span>
                            {this.state.product.name}
                        </span> 
                    </div>
                    <div className='ProductDetails-Description'>
                        <span>
                            {this.state.product.description}
                        </span> 
                    </div>
                </div>
    
                <div className='ProductDetails-footer'>
                    <div>
                        <ReactiveButton value='Add to Cart' product={this.state.product} size='Long' active/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductDetails

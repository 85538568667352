import React from 'react'
import ReceiptItem from '../components/ReceiptItem'
import '../styles/Receipt.css'

class Receipt extends React.Component {

    constructor(props){
        super(props)
    }


    render(){
        return (
            <div className='Receipt'>
                <div className='Receipt-header'> 
                    {this.props.header} 
                </div>
                <div className='Receipt-content'>
                    <ReceiptItem tag='Net Price' value={this.props.payload.value}/>
                    <ReceiptItem tag='Delivery' value={this.props.payload.delivery}/>   
                    <ReceiptItem tag='Total' value={ parseInt(this.props.payload.value) + parseInt(this.props.payload.delivery)}/>
                </div>
            </div>
        );
        
    }
}

export default Receipt
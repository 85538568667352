import React from 'react'
import { Button, Columns } from 'react-bulma-components'
import '../styles/PhoneInput.css'

class PhoneInput extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            className: 'PhoneNumber'
        } 
    }

    componentWillReceiveProps(){
        if (this.props.state === 'error'){
            this.setState({
                className: 'PhoneNumber-error'
            })
        }
        if (this.props.state === 'valid'){
            this.setState({
                className: 'PhoneNumber'
            })
        }
    }


    render(){
        return(
            <div className='PhoneInput'>   
                <input className={this.state.className} 
                        type='tel' value={this.props.value} 
                        placeholder={this.props.placeholder} 
                        onChange={this.props.onChange}
                        onClick={this.props.onClick}>
                
                </input>
            </div>    
        );
    }
}

export default PhoneInput
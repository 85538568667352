import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'
import LoadingIndicator from '../components/LoadingIndicator'
import '../styles/Affiliate.css'
import ApiService from '../services/api'
import DbService from '../services/db'
import Validator from '../utils/validator'


class Affiliate extends React.Component {

    constructor(props){
        super(props)
        this.cta = 'Generate your link'
        this.state = {
            name: '',
            number: '',
            link: '',
            region: '',
            style: {
                visibility: 'hidden'
            },
            loading: 'hidden'
        }

        this.apiService = new ApiService()
        this.dbService = new DbService()

        this.validator = new Validator()

        this.updateName = this.updateName.bind(this)
        this.updateNumber = this.updateNumber.bind(this)
        this.updateRegion = this.updateRegion.bind(this)
        this.generateCode = this.generateCode.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    updateName(e){
        e.preventDefault()
        this.setState({
            name: e.target.value
        })
    }

    updateNumber(e){
        e.preventDefault()
        this.setState({
            number: e.target.value
        })
    }

    updateRegion(e){
        e.preventDefault()
        this.setState({
            region: e.target.value
        })
    }

    generateCode(e){
        e.preventDefault()
        if (this.state.name != ''){
            if (this.validator.isPhoneNumber(this.state.number)){
                if (this.state.region != ''){
                    this.showLoading()
                this.apiService.createAffiliateLink(this.state.name, this.state.number, this.state.region).then( (res) => {
                    this.setState({
                        style: {
                            visibility: 'visible',
                        },
                        link: res.link
                    })

                    // save link
                    this.dbService.setAffiliateLink(res.link)

                    this.hideLoading()
                }).catch( (err) => {
                    //
                })
            }
            
            else {
                alert('Please choose a neighbourhood')
            }
                
            }
            else {
                alert('Please enter a valid phone number (07..) ')
            }
        }
        else {
            alert('Please fill in your name')
        }
        
    }

    closeModal(e){
        e.preventDefault()
        this.setState({
            style: {
                visibility: 'hidden'
            }
        })

        this.props.history.push('/')
    }

    showLoading(){
        this.setState({
            loading: 'visible'
        })
    }

    hideLoading(){
        this.setState({
            loading: 'hidden'
        })
    }

    render(){
        return(
            <div className='Affiliate'>
                <LoadingIndicator visibility= { this.state.loading } /> 
                <StickyBarTop title='Become an Affiliate' noback/> 
                <div className='Affiliates-content'>
                    <div className='Affiliate-label'>
                        Full Name
                    </div>
                    <div className='Affiliate-name'>
                        <input type="text" placeholder='Name' value={this.state.name} onChange={this.updateName}/>
                    </div>
                    
                    <div className='Affiliate-label'>
                        Phone Number
                    </div>
                    <div className='Affiliate-number'>
                        <input type="tel" placeholder='0712 ___ ___ ___' value={this.state.number} onChange={this.updateNumber}/>
                    </div>
                    <div className='Affiliate-label'>
                        Neighbourhood
                    </div>
                    <div className='Affiliate-region'>
                        <select name="" value={ this.state.region } ref="regions" onChange={this.updateRegion}>
                            <option value="" selected disabled>Select a Neighbourhood</option>
                            <option value="kileleshwa">Kileleshwa</option>
                            <option value="kilimani">Kilimani</option>
                            <option value="lavington">Lavington</option>
                            <option value="westlands">Westlands</option>
                            <option value="parklands">Parklands</option>
                            <option value="runda">Runda</option>
                            <option value="south b">South B</option>
                            <option value="south c">South C</option>
                            <option value="upper hill">Upper Hill</option> 
                            <option value="ngong road">Ngong Road</option>
                            <option value="buruburu">Buruburu</option>
                            <option value="dagoretti">Dagoretti</option>
                            <option value="dandora">Dandora</option>
                            <option value="eastleigh">Eastleigh</option>
                            <option value="embakasi">Embakasi</option>
                            <option value="gigiri">Gigiri</option>
                            <option value="highridge">Highridge</option>
                            <option value="kangemi">Kangemi</option>
                            <option value="karen">Karen</option>
                            <option value="kibera">Kibera</option>
                            <option value="kitisuru">Kitisuru</option>
                            <option value="langata">Langata</option>
                            <option value="madaraka">Madaraka</option>
                            <option value="majengo">Majengo</option>
                        </select>
                    </div>
                </div>
                <div className='Affiliate-modal'>
                    <div className="modal is-active" style={this.state.style}>
                        <div className="modal-background" onClick={this.closeModal}></div>
                        <div className="modal-content-bg">
                            Thanks 🎉, your affiliate link is 
                            <div className='Affiliate-Link'>
                                {this.state.link}
                            </div>
                        </div>
                        <button className="modal-close is-large" aria-label="close"></button>
                    </div>
                </div>
                <StickyBarBottom value={this.cta} onClick={this.generateCode}/>
            </div>
        )
    }
}

export default Affiliate
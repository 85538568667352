import React from 'react'
import '../styles/DeliveryMethod.css'

class DeliveryMethod extends React.Component {
    constructor(props){
        super(props)
        // card states
        // selected 
        // unselected
        // disabled / unavailable
        this.state = {
            fees: {
                express: '-',
                scheduled: '-'
            }
        }
    }

    componentDidMount(){
        
    }


    render(){

        return(
            <div className='DeliveryMethod'>
                <div className='DeliveryMethod-content'>
                    <div className='columns is-mobile is-gapless'>
                        <div className='column is-6'>
                            <div className={'DeliveryMethod-card express ' + this.props.style.express} value={this.props.fees.express}
                                    onClick={this.props.onExpressSelected}>

                                <div className={'DeliveryMethod-text ' + this.props.style.express} value={this.props.fees.express}>
                                    Express
                                </div>
                                <div className={'DeliveryMethod-price ' + this.props.style.express}  value={this.props.fees.express}>
                                    {this.props.fees.express} Ksh
                                </div>
                            </div>
                        </div>


                        <div className='column is-6'>
                            <div className={'DeliveryMethod-card scheduled ' + this.props.style.scheduled} value={this.props.fees.scheduled}
                                onClick={this.props.onScheduledSelected}>

                                <div className={'DeliveryMethod-text ' + this.props.style.scheduled} value={this.props.fees.scheduled}>
                                    Next Day
                                </div>
                                <div className={'DeliveryMethod-price ' + this.props.style.scheduled} value={this.props.fees.scheduled}>
                                    {this.props.fees.scheduled} Ksh
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DeliveryMethod
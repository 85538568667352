import axios from 'axios'

class AuthService {
    constructor(){
        this.config = {
            'authUrl': 'https://ccg-auth.appspot.com/api'
        }
    }
    
    async login(phoneNumber) {
    
        // post /send
        const endpoint = '/send'
        let payload = {
            'phone_number': phoneNumber
        }
        let res = await axios.post(this.config.authUrl + endpoint, payload)
        let data = res.data
        return data
    }

    async verify(phoneNumber, otp) {
        // post verify
        const endpoint = '/verify'
        let payload = {
            'phone_number': phoneNumber,
            'otp': otp
        }

        let res = await axios.post(this.config.authUrl + endpoint, payload)
        let data = res.data
        return data
    }

    logout(){
        return null
    }

}



export default AuthService
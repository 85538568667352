import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import EmptyAddress from '../components/EmptyAddress'
import AddressItem from '../components/AddressItem'
import StickyBarBottom from '../components/StickyBarBottom'
import DbService from '../services/db'
import '../styles/Addresses.css'


class Addresses extends React.Component{

    constructor(props){
        super(props)
        this.goBack = this.goBack.bind(this)
        this.newAddress = this.newAddress.bind(this)
        this.dbService = new DbService()
        this.state = {
            addresses: null
        }

        console.log(this.dbService.getAddresses())
    }

    newAddress(e){
        e.preventDefault()
        this.props.history.push('/address/new')
    }

    goBack(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    navigate(path){
        this.props.history.push(path)
    }

    render(){
        let addresses = this.dbService.getAddresses()

        if (addresses.length != 0){

            this.state.addresses = addresses.map( (value, index ) => { 
                let link = '/address/' + index      
                return <AddressItem address={value} key={index} link={link}/>    
             })

            return(
                <div className='Addresses'>
                    <StickyBarTop title='My Addresses' onBackPress={this.goBack}/> 
                    {this.state.addresses}
                    <StickyBarBottom value='New Address' onClick={this.newAddress}/>
                </div>
            )
        }
        else {
            return (
                <div className='Addresses'>
                    <StickyBarTop title='My Addresses' onBackPress={this.goBack}/> 
                    <EmptyAddress onClick={() => this.navigate('/address/new')} /> 
                </div>
            )
        }
        
    }
}

export default Addresses
import React from 'react'
import '../styles/Login.css'
import PhoneInput from './PhoneInput'
import BigButton from './BigButton'
//
import AuthService from '../services/auth'

import H1 from './H1'
import store from 'storejs'

class Login extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            phoneNumber: '',
            validation: 'valid'
        }
        this.updateInputValue = this.updateInputValue.bind(this)
        this.inputClick = this.inputClick.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.authService = new AuthService()
    }

    async handleClick(e) {
       e.preventDefault()
       // login
       // navigate to verify
       this.authService.login(this.state.phoneNumber).then( () => {

            window.phoneNumber = this.state.phoneNumber
            this.props.history.push('/verify')
       }).catch( (error) =>  {

           this.setState({
               validation: 'error'
           })
           this.forceUpdate()
       })
    }

    updateInputValue(e){
        e.preventDefault()
        this.setState({
            'phoneNumber': e.target.value
        })
    } 

    inputClick(e){
        e.preventDefault()
        this.setState({
            validation: 'valid'
        })
        this.forceUpdate()
    }  
    
    render() {
        return (
            <div className='Login'>
                <header className='Login-header'>
                    <H1 value='Sign in'></H1>
                </header>
                <PhoneInput placeholder='Phone number' 
                            value= {this.state.phoneNumber} 
                            onChange={this.updateInputValue} onClick={this.inputClick} 
                            state={this.state.validation}/>
                
                <div className='Login-description'>
                There are many variations of passages of Lorem Ipsum available, 
                but the majority have suffered alteration in some form, by injected humour.
                </div>
                <BigButton name='Continue' onClick={this.handleClick}/>
            </div>
        );
    }
}

export default Login
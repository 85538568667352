import React from 'react'
import '../styles/SubscriptionItem.css' 

class SubscriptionItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='subitem'>
                <div className='subitem-content'>
                    <div className='columns is-mobile'>
                        <div className='column is-10'>
                            <div className='subitem-title'>
                                {this.props.title}
                            </div>
                            <div className='subitem-price'>
                                {this.props.price + ' Ksh'}
                            </div>
                        </div>
                        <div className='column is-2'>
                            <input className='subitem-input' type="number" onChange={this.props.onChange} placeholder='1'/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default SubscriptionItem
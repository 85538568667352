import React from 'react'
import EmptyAddressIcon from '../images/emptyAddress.svg'
import '../styles/EmptyAddress.css'


class EmptyAddress extends React.Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='EmptyAddress'>
                <div className='EmptyAddress-content'>
                        <img src={EmptyAddressIcon} />
                        <div className='EmptyAddress-message'>
                            You haven't set addresses yet.
                        </div>
                        <div className='EmptyAddress-add'>
                            <button onClick={this.props.onClick}>
                                + Add an Address
                            </button>
                        </div>
                    </div>
            </div>
        )
    }
}

export default EmptyAddress
import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import SearchBar from '../components/SearchBar'
import StickyMenuBottom from '../components/StickyMenuBottom'
import '../styles/Brands.css'
import ApiService from '../services/api'

import BrandItem from '../components/BrandItem'
import EmptyBrandItem from '../components/EmptyBrandItem'

class Brands extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            rows: null,
            brandsResult: null
        }
        this.brandRows = null
        this.apiService = new ApiService()
        this.LOADING_STATE_COUNT = 6
        this.handleSearchUpdate = this.handleSearchUpdate.bind(this)
    }

    componentDidMount(){
        //
        this.apiService.getBrands().then( (res) => {
            this.setState({
                brandsResult: res,
                rows: res
            })
        }).catch( (err) => {
            console.error(err);
        } )
    }

    handleSearchUpdate(e){
        e.preventDefault()
        let query = e.target.value

        // filter rows
        let rows = this.state.brandsResult
        let searchResults = rows.data.filter( (value) => {
            return (value.name).toLowerCase().startsWith(query.toLowerCase())
        })

        this.setState({
            rows: {
                data: searchResults
            }
        })

        this.forceUpdate()
    }

    render(){
        // check if brands rows is not null
        let rows = this.state.rows

        if (rows != null){
            let data = rows.data
            this.brandRows = data.map( (value, index) => {
                return <BrandItem name={value.name} key={value.id} id={value.id}/> 
            })
        }
        else {
            let brands = new Array(this.LOADING_STATE_COUNT).fill({ })
            this.brandRows = brands.map( (value, index) => {
                return <EmptyBrandItem/> 
            })
        }
        return (
            <div className='Brands'>
                <SearchBar onChange={this.handleSearchUpdate}/> 
                    <div className='Brands-content'>
                       {this.brandRows}
                    </div> 
                <StickyMenuBottom highlight='brands' /> 
            </div>
        );
    }
}

export default Brands
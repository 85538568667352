import React from 'react'
import '../styles/OTPPrompt.css'


class OTPPrompt extends React.Component {

    constructor(props){
        super(props)
        this.onChange = this.onChange.bind(this)
        
    }

    onChange(e){
        e.preventDefault()

        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }
        
    }

    render(){
        return(
            <div className='OTPPrompt'>
                <div className="modal visible">
                    <div className="modal-background"></div>
                    <div className="OTPPrompt modal-content">
                        <div className='OTPPrompt-header'>
                            Verify Phone Number
                        </div>
                        <hr/>
                        <div className='OTPPrompt-value'>
                            <input type='number'
                            placeholder='____' onChange={this.onChange}
                            maxLength='4'/>
                        </div>
                        <div className='OTPPrompt-cta'>
                            <button className='ripple' onClick={this.props.onVerify}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OTPPrompt
import React from 'react'
import '../styles/ReactiveButton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import DbService from '../services/db'

class ReactiveButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            active: this.props.active !== undefined,
            className: {
                active: ''
            }
        }
        this.dbService = new DbService()
        this.activeClassName= 'ReactiveButton-active'
    }

    componentDidMount(){
        if (this.props.size){
            this.setState({
                className: {
                    active: this.activeClassName + this.props.size
                }
            }) 
        }
        else {
            this.setState({
                className: {
                    active: this.activeClassName
                }
            })
        }

    }

    addToCart(productItem){
        this.setState({
            active: true
        })

        productItem.quantity = 1
        this.dbService.addToCart(productItem)
    }

    increase(productItem){
        productItem.quantity = 1
        this.dbService.addToCart(productItem)
        this.setState(this.state)
    }

    decrease(productItem){
        productItem.quantity = -1
        this.dbService.addToCart(productItem)
        this.setState(this.state)
    }

    render(){

        let active = this.state.active
        let quantity = this.dbService.getQty(this.props.product.id)

        

        if (active && quantity === 0){
            return(
                <div className={this.state.className.active}>
                   <button onClick={ () => this.addToCart(this.props.product)} 
                            onMouseUp={this.props.onRefresh} >
                       {this.props.value}
                   </button> 
                </div>
            );
        }
        else {
            return (
                <div className='ReactiveButton-inactive'>
                    <div className='columns is-mobile'>
                        <div className='column is-3'>
                            <button className='ReactiveButton-Decrease' 
                                        onClick={ () => this.decrease(this.props.product)}
                                        onTouchEnd={this.props.onRefresh} >
                                <FontAwesomeIcon icon={faMinus} /> 
                            </button>
                        </div>
                        <div className='column is-3'>
                           <div className='ReactiveButton-Qty'>
                               <span> { quantity } </span>
                               
                           </div>
                        </div>
                        <div className='column is-3'>
                           <button className='ReactiveButton-Increase' 
                                    onClick={ () => this.increase(this.props.product)}
                                    onTouchEnd={ this.props.onRefresh }>
                                <FontAwesomeIcon icon={faPlus} /> 
                           </button> 
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default ReactiveButton
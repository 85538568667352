import React from 'react'
import '../styles/LoadingIndicator.css'
import LoadingSpinner from '../images/circles.svg'

class LoadingIndicator extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        let style = {
            visibility: this.props.visibility
        }

        return(
            <div className='LoadingIndicator' style={ style }>
                <div className="modal is-active">
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        <img src={LoadingSpinner}/>
                    </div>
                    <button className="modal-close is-large" aria-label="close"></button>
                </div>
            </div>
        );
    }
}

export default LoadingIndicator
import React from 'react'
import '../styles/OrderUnitItem.css'

class OrderUnitItem extends React.Component{

    constructor(props){
        super(props)
    }


    render(){
        return (
            <div className='OrderUnitItem'>
                <div className='columns is-mobile'>
                    <div className='column is-3'>
                        <div className='OrderUnitItem-Image'>
                            <img src={this.props.image} alt=""/>
                        </div>
                    </div>
                    <div className='column is-5'>
                        <div className='OrderUnitItem-Name'>
                            {this.props.name}
                        </div>
                        <div className='OrderUnitItem-Price'>
                            Ksh.{this.props.price}
                        </div>
                    </div>
                    <div className='column is-4'>
                        <div className='OrderUnitItem-Quantity'>
                            {this.props.qty}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default OrderUnitItem
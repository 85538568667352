import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'
import '../styles/Final.css'
import OrderConfirmedIcon from '../images/order-confirmed.png'
import { withRouter } from 'react-router-dom'

class Final extends React.Component {

    constructor(props){
        super(props)
        this.id = this.props.match.params.id 
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e){
        e.preventDefault()
        this.props.history.push('/')
    }

    render() {
        return(
            <div className='Final'>
                <StickyBarTop title='Confirmation' noback/>
                    <div className='Final-content'>
                        <div className='Final-confirmed'>
                        CONFIRMED
                        </div>
                        <div className='Final-thankyou'>
                        Thank you, your order has been submitted, Order reference
                            <div className='Final-OrderReference'>
                                <span>
                                    { this.id }
                                </span>
                            </div>
                        </div>
                        <img src={ OrderConfirmedIcon } />
                    </div>
                <StickyBarBottom value='Continue Shopping' onClick={this.handleClick}/>
            </div>
        )
    }
}

export default Final
import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import '../styles/Contact.css'
import StickyBarBottom from '../components/StickyBarBottom'
import OTPPrompt from '../components/OTPPrompt'
import DbService from '../services/db'
import Validator from '../utils/validator'

class Contact extends React.Component {

    constructor(props){
        super(props)
        //
        this.TAG_NEW = 'new'
        this.WRITE_NEW = 'new'
        this.WRITE_EDIT = 'edit'
        //
        this.state = {
            name: '',
            number: '',
            id: null,
            writeType: null,
            cta: null
        }

        this.updateName = this.updateName.bind(this)
        this.updateNumber = this.updateNumber.bind(this)
        this.writeContact = this.writeContact.bind(this)
        this.goBack = this.goBack.bind(this)

        this.dbService = new DbService()
        this.validator = new Validator()
    }

    componentDidMount(){
        let id = this.props.match.params.id
        //
        this.setState({
            id: id
        })
        //
        if (id == this.TAG_NEW) {
            this.setState({
                writeType: this.WRITE_NEW,
                cta: 'Create Contact'
            })
        }
        else {
            let contacts = this.dbService.getContacts()
            const index = parseInt(id)
            console.log(index)
            //
            this.setState({
                name: contacts[index].name,
                number: contacts[index].number,
                writeType: this.WRITE_EDIT,
                cta: 'Update Contact'
            })
        }
    }

    updateName(e){
        e.preventDefault()
        this.setState({   
            name: e.target.value
        })
    }

    updateNumber(e){
        e.preventDefault()
        this.setState({
            number: e.target.value
        })
    }

    writeContact(e){
        e.preventDefault()

        // -> new contacts
        // auth with firebase
        // validate phone number  
        // create customer
        // store firebase id + customer id in local storage

        // -> existing contacts
        // -> verify phone number at checkout
        // -> create customer
        // -> update contact with firebase id + customer id in local storage

        let contact = {
            name: this.state.name,
            number: this.state.number
        }

        // save to store
        if (this.state.writeType == this.WRITE_NEW){
            // check if all fields are present
            console.log(this.validator.isEmpty(contact))
            if (this.validator.isEmpty(contact).key != null){
                alert(`Please fill in your ${this.validator.isEmpty(contact).key}`);
            }
            else{
                // test if phone number is valid
                if (this.validator.isPhoneNumber(contact.number)){
                    this.dbService.saveContact(contact)
                    this.props.history.goBack()
                }
                else {
                    alert('Please use a valid phone number')
                }
                
            }
            
        }
        else {
            this.dbService.updateContact(parseInt(this.state.id) , contact)
            this.props.history.goBack()
        }
        
        
       
    }

    goBack(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    render(){
        return(
            <div className='Contact'>
                <StickyBarTop title='New Contact' onBackPress={this.goBack}/> 
                <div className='Contact-content'>
                    <div className='Contact-label'>
                        Name
                    </div>
                    <div className='Contact-name'>
                        <input type="text" placeholder='Name' value={this.state.name} onChange={this.updateName} />
                    </div>
                    <div className='Contact-label'>
                        Phone Number
                    </div>
                    <div className='Contact-number'>
                        <input type="tel" placeholder='0712 ___ ___ ___' value={this.state.number} onChange={this.updateNumber}/>
                    </div>
                </div>
                <StickyBarBottom value={this.state.cta} onClick={this.writeContact}/> 
            </div>
        )
    }
}

export default Contact
import React from 'react'
import '../styles/Address.css'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'
import DbService from '../services/db'


class Address extends React.Component {
    constructor(props){
        super(props)
        //
        this.TAG_NEW = 'new'
        this.WRITE_NEW = 'new'
        this.WRITE_EDIT = 'edit'
        //

        this.goBack = this.goBack.bind(this)
        //
        this.writeAddress = this.writeAddress.bind(this)
        this.updateRegion = this.updateRegion.bind(this)
        this.updateType = this.updateType.bind(this)
        this.updateDescription = this.updateDescription.bind(this)
        this.updateAddress = this.updateAddress.bind(this)
        this.updateDeliveryInstructions = this.updateDeliveryInstructions.bind(this)

        //

        this.state = {
            description: '',
            region: '',
            type: '',
            address: '',
            instructions: '',
            writeType: null,
            cta: null
        }

        this.dbService = new DbService()
    }

    componentDidMount(){
        let id = this.props.match.params.id
        //
        this.setState({
            id: id
        })
        //
        if (id == this.TAG_NEW) {
            this.setState({
                writeType: this.WRITE_NEW,
                cta: 'Create Address'
            })
        }
        else {
            let addresses = this.dbService.getAddresses()
            const index = parseInt(id)
            console.log(index)

            this.setState({
                description: addresses[index].description,
                region: addresses[index].region,
                type: addresses[index].type,
                address: addresses[index].address,
                instructions: addresses[index].instructions,
                writeType: this.WRITE_EDIT,
                cta: 'Update Address'
            })
        }
    }

    goBack(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    writeAddress(e){
        e.preventDefault()
        // add region + type
        var region = this.refs.regions.value
        var type = this.refs.addressType.value
        this.setState({
            region: region,
            type: type
        }, () => {

            let address = {
                description: this.state.description,
                region: region,
                type: type,
                address: this.state.address,
                instructions: this.state.instructions
            }

            console.log(address)

            // save to store
            console.log(this.state)
            if (this.state.writeType == this.WRITE_NEW){
                this.dbService.saveAddress(address)
            }
            else {
                this.dbService.updateAddress(parseInt(this.state.id) , address)
            }
            
            this.props.history.goBack()
        })

    }

    updateDescription(e){
        e.preventDefault()
        this.setState({
            description: e.target.value
        })
    }

    updateRegion(e){
        e.preventDefault()
        this.setState({
            region: e.target.value
        })
    }

    updateType(e){
        e.preventDefault()
        this.setState({
            type: e.target.value
        })
    }

    updateAddress(e){
        e.preventDefault()
        this.setState({
            address: e.target.value
        })
    }

    updateDeliveryInstructions(e){
        e.preventDefault()
        this.setState({
            instructions: e.target.value
        })
    }


    render(){
        return (
            <div className='Address'>
                <StickyBarTop title='New Delivery Address' onBackPress={this.goBack}/>
                <div className='Address-content'>  
                    <div className='Address-label'>
                            Description
                    </div>
                    <div className='Address-description'>
                        <input type="text" value={this.state.description} placeholder='eg: My House' onChange={this.updateDescription}/>
                    </div>
                    
                    <div className='Address-label'>
                        Select your region
                    </div>

                    <div className='Address-regions'>
                        <select name="" value={ this.state.region } ref="regions" onChange={this.updateRegion}>
                            <option value="" selected disabled>Select a Neighbourhood</option>
                            <option value="kileleshwa">Kileleshwa</option>
                            <option value="kilimani">Kilimani</option>
                            <option value="lavington">Lavington</option>
                            <option value="westlands">Westlands</option>
                            <option value="parklands">Parklands</option>
                            <option value="runda">Runda</option>
                            <option value="south b">South B</option>
                            <option value="south c">South C</option>
                            <option value="upper hill">Upper Hill</option> 
                            <option value="ngong road">Ngong Road</option>
                            <option value="buruburu">Buruburu</option>
                            <option value="dagoretti">Dagoretti</option>
                            <option value="dandora">Dandora</option>
                            <option value="eastleigh">Eastleigh</option>
                            <option value="embakasi">Embakasi</option>
                            <option value="gigiri">Gigiri</option>
                            <option value="highridge">Highridge</option>
                            <option value="kangemi">Kangemi</option>
                            <option value="karen">Karen</option>
                            <option value="kibera">Kibera</option>
                            <option value="kitisuru">Kitisuru</option>
                            <option value="langata">Langata</option>
                            <option value="madaraka">Madaraka</option>
                            <option value="majengo">Majengo</option>
                        </select>
                    </div>
                    <div className='Address-label'>
                        Type
                    </div>
                    <div className='Address-type'>
                        <select name="" value={this.state.type} ref="addressType" onChange={this.updateType}>
                            <option value="home">Home</option>
                            <option value="office">Office</option>
                            <option value="kiosk">Kiosk</option>
                            <option value="other">Other</option>
                        </select>      
                    </div>
                    <div className='Address-label'>
                        Address
                    </div>
                    <div className='Address-text'>
                        <textarea name="" value={this.state.address} id="" placeholder='Address' onChange={this.updateAddress}>

                        </textarea>
                    </div>
                    <div className='Address-label'>
                        Additional instructions
                    </div>
                    <div className='Address-instructions'>
                        <textarea name="" value={this.state.instructions} id="" placeholder='Delivery instructions' onChange={this.updateDeliveryInstructions}>
                            
                        </textarea>
                    </div>
                </div>

                <StickyBarBottom value={this.state.cta} onClick={this.writeAddress}/>

                
            </div>
        )
    }

}

export default Address
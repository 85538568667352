import React from 'react'
import '../styles/BigButton.css'

class BigButton extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            className: 'BigButton'
        }

        this.handleTouchStart = this.handleTouchStart.bind(this)
        this.handleTouchEnd = this.handleTouchEnd.bind(this)
    }


    handleTouchEnd(e){
    
        this.setState({
            className: 'BigButton'
        })
    }

    handleTouchStart(e){
        
        this.setState({
            className: 'BigButton-clicked'
        })
    }

    render(){
        return (
            <div className={this.state.className}
                     onClick={this.props.onClick} 
                         onTouchStart={this.handleTouchStart}
                            onTouchEnd={this.handleTouchEnd}>
                                {this.props.name}
            </div>
        );
    }
}

export default BigButton
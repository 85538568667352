import React from 'react'
import StickyBarTop from '../components/StickyBarTop'

import '../styles/Favorites.css'

class Favorites extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className='Favorites'>
                <StickyBarTop title='Favorites' />
                <div className='Favorites-content'>

                </div>
            </div>
        );
    }
}

export default Favorites
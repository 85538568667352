import React from 'react'
import '../styles/EmptyProductItem.css'


class EmptyProductItem extends React.Component {

    constructor(props){
        super(props)
    }

    componentDidMount(){
    }

    render(){
        return (
            <div className='EmptyProductItem'>
                <div className='columns is-mobile is-gapless'>
                    <div className='column is-4'>
                        <div className='EmptyProductItem-image shine'>

                        </div>
                    </div>
                    <div className='column is-8'>
                        <div className='EmptyProductItem-text shine'>
                            
                        </div>
                        <div className='EmptyProductItem-subtext shine'>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmptyProductItem
import React from 'react'
import '../styles/BasketItem.css'
import ReactiveButton from '../components/ReactiveButton'

class BasketItem extends React.Component{

    constructor(props){
        super(props)
    }


    render(){
        return(
            <div className='BasketItem'>
                <div className='columns is-mobile is-gapless'>
                    <div className='column is-3'>
                        <div className='BasketItem-Image'>
                            <img src={this.props.product.image} alt=""/>
                        </div>
                    </div>
                    <div className='column is-4'>
                        <div className='BasketItem-Name'>
                        {this.props.product.name}
                        </div>
                        <div className='BasketItem-Price'>
                        Ksh {this.props.product.price}
                        </div>
                    </div>
                    <div className='column is-5'>
                        <div className='BasketItem-Controls'>
                            <ReactiveButton value='Add' product={this.props.product} active/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BasketItem
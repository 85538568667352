import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import LoadingIndicator from '../components/LoadingIndicator'
import StickyMenuBottom from '../components/StickyMenuBottom' 
import PromocodeImage from '../images/promocode.svg'
import '../styles/Redeem.css'
import ApiService from '../services/api'
import DbService from '../services/db'


class Redeem extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            code: null,
            visibility: 'hidden',
            inputStyle: 'Redeem-Input'
        }

        this.apiService = new ApiService()
        this.dbService = new DbService()

        this.handleClick = this.handleClick.bind(this)
        this.update = this.update.bind(this)
    }

    showIndicator(){
        this.setState({
            visibility: 'visible'
        })
    }

    hideIndicator(){
        this.setState({
            visibility: 'hidden'
        })
    }

    highlightInvalidInput(){
        this.setState({
            inputStyle: 'Redeem-Input-Error'
        })
    }

    highlightValidInput(){
        this.setState({
            inputStyle: 'Redeem-Input'
        })
    }

    handleClick(e){
        e.preventDefault()
        this.showIndicator()
        this.apiService.getPromotionId(this.state.code.toUpperCase()).then( (res) => {
            this.dbService.setPromotion(res[0])
            //
            this.hideIndicator()
            //
            alert('Your promotion code has been applied 🎉')
            this.props.history.push('/')

        }).catch( (err) => {

            this.hideIndicator()
            this.highlightInvalidInput()           
        })
        
    }

    update(e){
        e.preventDefault()
        this.highlightValidInput()
        this.setState({
            code: e.target.value
        })
    }

    render(){
        return(
            <div className='Redeem'>
                <LoadingIndicator visibility= {this.state.visibility} /> 
                <StickyBarTop title='Promotions' noback/>
                <div className='Redeem-Content'>
                    <div className='Redeem-Image'>
                        <img src= {PromocodeImage}/>
                    </div>
                    <div className={this.state.inputStyle}>
                        <input type="text" placeholder='Code' onChange={this.update}/>
                    </div>
                    <div className='Redeem-Button'>
                        <button onClick={this.handleClick}>
                            Redeem
                        </button>
                    </div>
                    <div className='Redeem-Text'>
                        <span>
                        When you redeem your code, a discount will be automatically applied to certain list of products
                        </span>
                    </div>
                </div>
                <StickyMenuBottom highlight='feed'/> 
            </div>
        )
    }

}

export default Redeem
import React from 'react'
import Receipt from '../components/Receipt'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'
import OrderUnitItem from '../components/OrderUnitItem'
import DeliveryMethod from '../components/DeliveryMethod'
import LoadingIndicator from '../components/LoadingIndicator'
import PaymentPrompt from '../components/PaymentPrompt'
import DbService from '../services/db'
import ApiService from '../services/api'
import '../styles/Checkout.css'


class Checkout extends React.Component {

    constructor(props){
        super(props)
        this.dbService = new DbService()
        this.apiService = new ApiService()
        //
        this.onContactChange = this.onContactChange.bind(this)
        this.onAddressChange = this.onAddressChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.goBack = this.goBack.bind(this)
        this.onExpressSelected = this.onExpressSelected.bind(this)
        this.onScheduledSelected = this.onScheduledSelected.bind(this)

        this.addresses = this.dbService.getAddresses()

        this.TAG_EXPRESS = 'Express'
        this.TAG_SCHEDULED = 'Scheduled'

        // loading indicator 
        // FIXME: move this to an enum class
        this.BASKET_MOQ = 100
        this.state = {
            visibility: {
                progress: 'hidden',
                modal: ''
            },
            // to be generated <select> options
            addressOptions: null,
            contactOptions: null,
            selectedContact: null,
            receipt: {
                    value: this.dbService.getCartValue(),
                    fees: {
                        express: '-',
                        scheduled: '-'
                    },
                    delivery: 0, // delivery cost
                    tag: '' // delivery type
            },
            style: {
                deliveryMethod: {
                    express: '',
                    scheduled: ''
                }
            }
        }
    }

    componentDidMount(){
        this.fetchDeliveryFees()
    }

    onContactChange(e){
        e.preventDefault()
       
    }

    onAddressChange(e){
        e.preventDefault()
        this.fetchDeliveryFees()
    }

    fetchDeliveryFees(){
        if (this.refs.addresses){
            this.showLoadingIndicator()
            let cartValue = this.dbService.getCartValue()
            let addresses = this.dbService.getAddresses()
            let addressIndex = this.refs.addresses.value
            //
            this.apiService.fetchDeliveryFees(addresses[addressIndex].region, cartValue).then( (res) => {
                this.hideLoadingIndicator()

                //
                console.log(res.data)
                //
                this.resetDeliveryOptionHighlight()
                this.highlightDeliveryOption('express')
                //
                this.setState({
                    receipt: {
                        value: cartValue,
                        fees: res.data,
                        delivery: res.data.express,
                        tag: this.TAG_EXPRESS
                    }
                })

            }).catch( (err) => {
                console.log(err)
            })
        }

    }

    showLoadingIndicator(){
        this.setState({
            visibility: {
                progress: 'visible'
            }
        })
    }

    hideLoadingIndicator(){
        this.setState({
            visibility: {
                progress: 'hidden'
            }
        })
    }

    showPaymentModal(){
        this.setState({
            visibility: {
                modal: 'is-active'
            }
        })
    }

    handleClick(e) {
        e.preventDefault()
        let cartValue = this.dbService.getCartValue()
        if (cartValue >= this.BASKET_MOQ){
        // check addresses + contacts 
        let addresses = this.dbService.getAddresses()
        let contacts = this.dbService.getContacts()

        if (addresses.length == 0 || contacts.length == 0){
            alert('Please set your address and contact details')
        }
        else {
            this.showLoadingIndicator()

    
            let units = this.dbService.getCart()
            // get contact + address
            
            let contactIndex = this.refs.contacts.value
            let addressIndex = this.refs.addresses.value
            let contact = this.dbService.getContactDetails(contactIndex)
            let address = this.dbService.getAddressDetails(addressIndex)
            let deliveryFees = this.state.receipt.delivery
            let affiliate = this.dbService.getAffiliate()
            let promoCode = this.dbService.getPromotion()
            let tag = this.state.receipt.tag
    
            // get delivery type

            this.apiService.createOrder(contact, address, units, deliveryFees,tag, affiliate, promoCode).then( (res) => {
                this.hideLoadingIndicator()
    
                let order = {
                    id: res.id,
                    reference: res.fields['ID'],
                    created_at: res.createdTime,
                    price: res.fields['Total']
                }

                this.dbService.saveOrder(order)
                //
                this.dbService.clearCart()
                //
                this.dbService.resetAffiliate()
                //
                this.dbService.resetPromotion()
                //
                let summaryLink = '/summary/' + order.reference
                this.props.history.push(summaryLink)
            }).catch( (err) => {
                console.error(err)
            })
        }
        }
        else {
            alert('Your minimum order should not be less than 100 Ksh.')
        }
    }

    goBack(){
        this.props.history.goBack()
    }

    onExpressSelected(e){
        e.preventDefault()

        if (this.addresses.length !== 0){
            this.resetDeliveryOptionHighlight()
            this.highlightDeliveryOption('express')
            let deliveryCost = parseInt(e.target.attributes.value.nodeValue)
            this.updateReceipt(deliveryCost, this.TAG_EXPRESS)
        }
        else {
            alert('Please set up an address')
        }
        
    }

    onScheduledSelected(e){
        e.preventDefault()
        if (this.addresses.length !== 0){
            this.resetDeliveryOptionHighlight()
            this.highlightDeliveryOption('scheduled')
            let deliveryCost = parseInt(e.target.attributes.value.nodeValue)
            console.log(deliveryCost)
            this.updateReceipt(deliveryCost, this.TAG_SCHEDULED)
        }
        else {
            alert('Please set up an address')
        }
    }

    resetDeliveryOptionHighlight(){
        let currentState = this.state
        currentState.style.deliveryMethod = {
            express: '',
            scheduled: ''
        }

        this.setState(currentState)
    }

    highlightDeliveryOption(option){
        let currentState = this.state
        if (option === 'express'){
            currentState.style.deliveryMethod.express = 'selected'
        }
        if (option === 'scheduled'){
            currentState.style.deliveryMethod.scheduled = 'selected'
        }
    }

    updateReceipt(deliveryCost, tag){
        let currentState = this.state
        currentState.receipt.delivery = parseInt(deliveryCost)
        currentState.receipt.tag = tag
        this.setState(currentState)
    }


    render(){
        
        let orderUnitRows = this.dbService.getCart().map( (value, index) => {
            return <OrderUnitItem key={value.productId} name={value.name} image={value.image} price={value.price} qty={value.quantity} />
        } )

        // addresses 
        let addresses = this.dbService.getAddresses()
        let contacts = this.dbService.getContacts()

        //
        if (addresses.length != 0){
            let addressRows = addresses.map( (value, index) => {
                return <option value={ index }>{ value.description  } ({value.region})</option>
            } )

            this.state.addressOptions =[1].map((value, index) => {
                return (
                    <select name="addresses" id="" ref="addresses" onChange={this.onAddressChange}>
                            {addressRows}
                    </select>
                )
            })

        }
        else {
            this.state.addressOptions = [1].map( (index, value) => {
                return (
                    <div className='Checkout-newAddress' 
                            onClick={ () => this.props.history.push('/address/new')}>
                        + Create a new Address
                    </div>
                )
            })
        }

        // contacts
        if (contacts.length != 0){
            let contactRows = contacts.map( (value, index) => {
                return <option value={ index }>{ value.name  } ({value.number})</option>
            } )

            this.state.contactOptions =[1].map((value, index) => {
                return (
                    <select name="contacts" id="" ref="contacts" onChange={this.onContactChange}>
                            {contactRows}
                    </select>
                ) 
            })
        }
        else {
            this.state.contactOptions = [1].map( (index, value) => {
                return (
                    <div className='Checkout-newContact' 
                            onClick={() => this.props.history.push('/contact/new')} >
                        + Create a new Contact
                    </div>
                )
            })
        }
        
        return (
            <div className='Checkout'>
                <LoadingIndicator visibility= { this.state.visibility.progress } /> 
                <StickyBarTop title='Checkout' onBackPress={this.goBack}/>
                <div className='Checkout-content'>
                    <div className='Checkout-units-header'>Items</div>
                    <div className='Checkout-units'>
                        { orderUnitRows }
                    </div>

                    <div className='Checkout-address-header'>Address</div>
                        <div className='Checkout-addresses'>
                            {this.state.addressOptions}
                        </div>

                    <div className='Checkout-contact-header'>Contact Person</div>
                        <div className='Checkout-contacts'>
                            {this.state.contactOptions}
                        </div>
                    
                    
                    <div className='Checkout-delivery-header'>Delivery Options</div>
                    <DeliveryMethod onExpressSelected={this.onExpressSelected} 
                                    onScheduledSelected= {this.onScheduledSelected}
                                    style={this.state.style.deliveryMethod}
                                    fees = {this.state.receipt.fees}/>

                    <Receipt header='Receipt' payload={this.state.receipt} /> 
                   
                </div> 
                <PaymentPrompt amount={this.state.receipt.value} 
                                from={this.state.selectedContact}
                                visibility={this.state.visibility.modal}/> 
                <StickyBarBottom value='Order Now' onClick={this.handleClick}/>
            </div>
        );
    }
}

export default Checkout
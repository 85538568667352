import React from 'react'
import '../styles/OTPInput.css'
class OTPInput extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            className: 'OTPCode'
        }
    }

    componentWillReceiveProps(){
        if (this.props.state === 'error'){
            this.setState({
                className: 'OTPCode-error'
            })
        }

        if (this.props.state === 'valid'){
            this.setState({
                className: 'OTPCode'
            })
        }

        this.forceUpdate()
    }

    render(){
        return(
            <div className='OTPInput'>   
                <input className= {this.state.className} type='number'
                            placeholder='______' 
                            onChange= {this.props.onChange}
                            onClick= {this.props.onClick}>
                </input>
            </div>    
        );
    }
}

export default OTPInput

class Validator {
    constructor(){
        
    }

    // check wether an object is missing a field
    isEmpty(object){
        let keys = Object.keys(object)
        console.log(keys)
        let emptyKey = null
        keys.forEach(key => {
            // check if null or empty character
            if (object[key] == null || object[key].split(' ').join('') == ''){
                emptyKey = key
            }
        });

        return {
            'key': emptyKey
        }
    }

    // check if a phone number is valid
    isPhoneNumber(number){
        // https://regex101.com/ code generator
        const regex = /^(?:254|\+254|0)?(7|1)([0-9]{8})$/;
        let m;
        let valid = false

        if ((m = regex.exec(number)) !== null) {
            // The result can be accessed through the `m`-variable.
            m.forEach((match, groupIndex) => {
                valid = true
            });
        }
        
        return valid
    }

}


export default Validator
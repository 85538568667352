import React from 'react'
import '../styles/ReceiptItem.css'

class ReceiptItem extends React.Component {

    constructor(props){
        super(props)
    }

    render() {
        return(
            <div className='ReceiptItem' >
            <div className='columns is-mobile'>
                <div className='column is-6'>
                    <div className='ReceiptItem-tag'>
                        {this.props.tag}
                    </div>
                </div>

                <div className='column is-6'>
                    <div className='ReceiptItem-value'>
                        KES {this.props.value}
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default ReceiptItem
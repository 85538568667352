import React from 'react'
import '../styles/StickyBarTop.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class StickyBarTop extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            className : 'StickyBarTop-back'
        }
    }

    componentDidMount(){
        if (this.props.noback){
            this.setState({
                className: 'StickyBarTop-noback'
            })
        }
    }

    render(){
        return(
            <div className='StickyBarTop'>
                    <div className='columns is-mobile is-gapless'>
                        <div className='column is-1'>
                            <div className={this.state.className} onClick={this.props.onBackPress}>
                                <FontAwesomeIcon className='icon' icon={faArrowLeft} />
                            </div> 
                        </div>
                        <div className='column is-9'>
                            <span className='ProductDetails-Title'>
                                {this.props.title}
                            </span>
                        </div>
                        <div className='column is-2'>
                            <div className='StickyBarTop-icon' onClick={this.props.onClick}>
                                <FontAwesomeIcon className='icon' icon={this.props.icon} />
                            </div>
                        </div>
                        
                    </div>
            </div>
        );
    }
}

export default StickyBarTop
import React from 'react'
import '../styles/ProductThumbnail.css'

function ProductThumbnail(){

    return (
       <div className='ProductThumbnail'>
           <div className='ProductThumbnail-Image'>
                <img src="https://pngimage.net/wp-content/uploads/2018/06/tide-png-2.png" alt=""/>
           </div>
           <div className='ProductThumbnail-Price'>
               Ksh 600
            </div>
       </div>
    );
}

export default ProductThumbnail
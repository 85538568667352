import React from 'react'
import '../styles/PaymentPrompt.css'

class PaymentPrompt extends React.Component {
    constructor(props){
        super(props)
        this.requestPayment = this.requestPayment.bind(this)
        this.BUTTON_ENABLED = 'Pay now'
        this.BUTTON_DISABLED = 'Requesting Payment'
        this.state = {
            button: {
                disabled: false,
                text: this.BUTTON_ENABLED
            },
            progress: {
                visible: false
            }
        }
    }

    requestPayment(e){
        e.preventDefault()
        // check state
        // paynow, rety -> enabled
        // requesting payment -> disabled
        console.log('Requesting payment')
        let isButtonEnabled = !this.state.button.disabled

        console.log(isButtonEnabled)

        if (isButtonEnabled){
            this.disabledButton()
            this.showProgress()
        }
    }


    enableButton(){
        this.setState({
            button: {
                disabled: false, 
                text: this.BUTTON_ENABLED
            }
        })
    }

    disabledButton(){
        this.setState({
            button: {
                disabled: true,
                text: this.BUTTON_DISABLED
            }
        })
    }

    showPendingMessage(){

    }

    showRetryButton(){

    }

    showPaidMessage(){

    }

    showErrorMessage(){

    }

    showProgress(){
        this.setState({
            progress: {
                visible: true
            }
        })
    }

    hideProgress(){
        this.setState({
            progress: {
                visible: false
            }
        })
    }

    render(){
        return(
            <div className='PaymentPrompt'>
                <div className={"modal " +  this.props.visibility}>
                    <div className="modal-background"></div>
                    <div className="PaymentPrompt modal-content">
                        <div className='PaymentPrompt-header'>
                            Pay with MPESA
                        </div>
                        <hr/>
                        <div className='PaymentPrompt-progress' hidden = {!this.state.progress.visible}>
                            <span style= {{ width: "100%"}}><span className="progress"></span></span>
                        </div>
                        <span className='PaymentPrompt-info'>
                            You are paying 
                        </span>
                        <div className='PaymentPrompt-amount'>
                            {this.props.amount} KES
                        </div>
                        <div className='PaymentPrompt-company'>
                            To <span>CCG TECHNOLOGIES LIMITED NAIROBI</span>
                        </div>
                        <span className='PaymentPrompt-info'>With 
                            <span className='PaymentPrompt-info highlighted'> {this.props.from} </span>
                        </span>
                        <div className='PaymentPrompt-cta'>
                            <button className='ripple' onClick={this.requestPayment} disabled={this.state.button.disabled}>
                                {this.state.button.text}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentPrompt
import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import StickyMenuBottom from '../components/StickyMenuBottom'
import AccountItem from '../components/AccountItem'
import SettingMenuItem from '../components/SettingMenuItem'
import DbService from '../services/db'
import { faPhoneAlt, faMapPin, faAddressBook, faList, faBug, faLightbulb, faLink} from '@fortawesome/free-solid-svg-icons'

import '../styles/Account.css'

class Account extends React.Component {
    
    constructor(props){
        super(props)
        this.openDial = this.openDial.bind(this)
        this.suggestProduct = this.suggestProduct.bind(this)
        this.reportBug = this.reportBug.bind(this)
        this.setPricingScheme = this.setPricingScheme.bind(this)
        this.showPricingScheme = this.showPricingScheme.bind(this)

        this.whatsappLink = 'whatsapp://send?text=&phone=+254759367298'
        this.dbService = new DbService()
        this.tapCount = 0
        this.state = {
            visibility: 'hidden'
        }
    }

    componentDidMount(){
        
    }

    openDial(e){
        e.preventDefault()
        window.open('tel:+254759367298')
    }

    suggestProduct(e){
        e.preventDefault()
        window.open(this.whatsappLink)
    }

    reportBug(e){
        e.preventDefault()
        window.open(this.whatsappLink)
    }

    setPricingScheme(e){
        e.preventDefault()
        this.dbService.setPricingScheme(e.target.value)
    }

    navigate(link){
        this.props.history.push(link)
    }

    showPricingScheme(e){
        this.tapCount += 1
        if (this.tapCount == 4){
            this.tapCount = 0
            this.setState({
                visibility: 'visible'
            })
        }
        
    }

    render(){
        return (
            <div className='Account'>
                <StickyBarTop title='Settings' noback/> 
                <div className='Account-header'>
                    <SettingMenuItem title='My Orders' icon={faList} onClick={ () => this.navigate('/orders')} />
                    <SettingMenuItem title='My Addresses' icon={faMapPin} onClick={ () => this.navigate('/addresses')} />
                    <SettingMenuItem title='My Contacts' icon={faAddressBook} onClick={ () => this.navigate('/contacts')} />
                    <SettingMenuItem title='My Affiliate Links' icon={faLink} onClick={ () => this.navigate('/affiliates/me')}/>
                    <SettingMenuItem title='Suggest a Product / Feature' icon={faLightbulb} onClick={this.suggestProduct}/>
                    <SettingMenuItem title='Report a Bug' icon={faBug} to={this.whatsappLink} onClick={this.reportBug}/>

                    <select onChange={this.setPricingScheme} class={this.state.visibility}>
                        <option value=""> 🏷️ Select a Pricing Scheme</option>
                        <option value="b2c">B2C</option>
                        <option value="b2b">B2B</option>
                    </select>

                </div>
                
                <div className='Account-content'>
                    
                    <AccountItem header='Help Center' content='Contact Us' icon={faPhoneAlt} onClick={this.openDial}/>
                    <AccountItem header='Version' content='0.0.1' onClick={this.showPricingScheme}/>
                </div>
                <StickyMenuBottom highlight='account'/>
            </div>
        );
    }
}

export default Account
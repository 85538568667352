import React from 'react'
import StickyMenuTop from '../components/StickyMenuTop'
import StickyMenuBottom from '../components/StickyMenuBottom'
import ProductItem from '../components/ProductItem'
import EmptyProductItem from '../components/EmptyProductItem'

import '../styles/Products.css'
import ApiService from '../services/api'
import DbService from '../services/db'

class Products extends React.Component{

    constructor(props){
        super(props)

        this.apiService = new ApiService()
        this.dbService = new DbService()
        
         // refresh, used to update cart value
         this.state = {
            products: null,
            refresh: true
        }

        this.cartValue = this.dbService.getCartValue()

        this.productRows = null
        // 
        this.collection = 'productDetails'
        this.LOADING_STATE_COUNT = 6
    }

    componentDidMount(){
        // init
        this.dbService.initCache()
        this.dbService.initCart()
        //
        let result = null
        let categoryName = this.props.match.params.name
        let brandId = this.props.match.params.id   
        
        // check for promocode
        let promocode = this.dbService.getPromotion()
        let scheme = this.dbService.getPricingScheme()
        
        if (promocode){
            scheme = 'promo'
        }

        // 
        if (categoryName){
            result = this.apiService.getProducts(categoryName, 'category', scheme)
        }
        
        if(brandId){
            result = this.apiService.getProducts(brandId, 'brand', scheme)
        }
        
        result.then( (res)=> {
            this.setState({
                products: res
            })
        
        }).catch( (err) => {
            console.error(err);
            
        })
    }

    handleClick(product){
        this.dbService.setCache(this.collection, product)
        let productDetailsPage = '/products/' + product.id
        this.props.history.push(productDetailsPage)
    }


    refresh(){
        this.setState({
            refresh: true
        })
    }

    render(){
            
        let products = this.state.products
        if (this.state.products){
            this.productRows = products.map( (value, index) => {
                // check if product exist
                return ( 
                         <ProductItem product={value} key={value.id} 
                                     onClick={ () => this.handleClick(value)} onRefresh={() => this.refresh()}/>
                )
            })

        }

        else {

           let products = new Array(this.LOADING_STATE_COUNT).fill({})

           this.productRows = products.map( (value, index) => {
               return (
                   <EmptyProductItem /> 
               )
           }) 
        }

        return (
            <div className='Products'>
                <StickyMenuTop value= {this.state.refresh} />
                    <div className='header-Products'>Explore Products</div>
                    <div className='content-Products'>
                        {this.productRows}
                    </div>
                <StickyMenuBottom highlight='home'/>
            </div>
        );
    }
}


export default Products
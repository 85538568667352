import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import '../styles/SupportButton.css'

class SupportButton extends React.Component{

    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className='SupportButton'>
                <div className='SupportButton-header'> {this.props.header}</div>

                <div className='SupportButton-content'>
                    <a href="whatsapp://send?text=Hi, I have a question &phone=+254759367298">
                    <div className='columns is-mobile'>
                        <div className='column is-1'>
                            <FontAwesomeIcon icon={faCommentAlt} />
                        </div>

                        <div className='column is-9'>
                            +254 759 367 298 
                        </div>

                        <div className='column is-2'>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </div>  
                    </a>
    
                </div>
                 
            </div>  
        )
    }
}

export default SupportButton
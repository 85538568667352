import React from 'react'
import '../styles/SettingMenuItem.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import '../styles/SettingMenuItem.css'

class SettingMenuItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='SettingMenuItem' onClick={this.props.onClick}>
                <div className='columns is-mobile'>
                    <div className='column is-1'>
                        <div className='SettingMenuItem-icon'>
                            <FontAwesomeIcon icon={this.props.icon} />
                        </div>
                    </div>
                    <div className='column is-9'>
                        <div className='SettingMenuItem-title'>
                            {this.props.title}
                        </div>
                    </div>
                    <div className='column is-2'>
                            <div className='SettingMenuItem-outbound'>
                                <FontAwesomeIcon icon={faChevronRight} /> 
                            </div>
                    </div>
                </div>
        </div>
        );
    }
}


export default SettingMenuItem
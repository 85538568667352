const initialState = {
    cartValue: 0
}

const reducer = (state= initialState, action) => {
    const newState = {...state}

    return newState
}

export default reducer
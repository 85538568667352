import React from 'react'
import { Link } from 'react-router-dom'
import ProductThumbnail from '../components/ProductThumbnail'
import '../styles/CategoryItem.css'


class CategoryItem extends React.Component{
    constructor(props){
        super(props)
        this.inlineStyle = {
            backgroundImage: `url(${props.backgroundImage})`
        }

        this.categoryPage = '/categories/' + this.props.url
    }

    render(){
        if (!this.props.empty){
            return (
                <div className='CategoryItem' onClick={this.handleClick}>
                    <Link to={this.categoryPage}>
                   
                    <div className='CategoryItem-content'>
                        <div className='fade-in-bck'>
                            <div className='CategoryItem-Image' style={this.inlineStyle}>
                                <div className='CategoryItem-Name'>
                                    {this.props.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    </Link>
                    
                </div>
            );
        }
        else {
            return(
                <div className='EmptyCategoryItem'>
                    <div className='EmptyCategoryItem-Content'>
                        <div className='EmptyCategoryItem-Name loading'>
                                            
                        </div>
                        <div className='EmptyCategoryItem-Image loading'>
                                 
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CategoryItem
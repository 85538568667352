import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import SubscriptionItem from '../components/SubscriptionItem'
import Receipt from '../components/Receipt'
import LoadingIndicator from '../components/LoadingIndicator'
import PaymentPrompt from '../components/PaymentPrompt'
import StickyBarBottom from '../components/StickyBarBottom'
import '../styles/Subscriptions.css'
import ApiService from '../services/api'

class Subscriptions extends React.Component {

    constructor(props){
        super(props)
        this.apiService = new ApiService()
        this.handleClick = this.handleClick.bind(this)

        this.state = {
            customer: {},
            items: [],
            units: [],
            receipt: {},
            visibility: {
                loadingIndicator: 'hidden',
                paymentPrompt: 'hidden',
                page: 'hidden'
            }
        }

        this.itemRows = null
    }
    
    componentDidMount(){
        // fetch subscription details
        this.apiService.fetchSubscriptionDetails(this.props.match.params.id).then( (res) =>{
            this.setState(res.data)
            // set units from items
            let items = this.state.items
            let units = []
            items.forEach((item) => {
                units.push(
                    {
                        'id': item.id,
                        'quantity': 1
                    }
                )
            })

            this.setState({ units: units})
            this.setState({
                receipt: this.getSubscriptionValue()
            })

            this.showPage()

        })
        
    }

    handleClick(e){
        e.preventDefault()
        this.showLoadingIndicator()
        let address = {
            address: this.state.customer.address,
            region: this.state.customer.area,
            instructions: null
        }

        // FIXME: relative to delivery fee
        const tag = 'Express'
        this.apiService.createOrder(
            this.state.customer,address,this.state.units, this.state.receipt.delivery,tag,null,null
        ).then( (res) => {
            console.log(res)
            this.hideLoadingIndicator()
            // redirect to summary with order reference
            console.log(res)
            let summaryLink = '/summary/' + res.id
            this.props.history.push(summaryLink)
        })
    }

    // ui related methods
    showLoadingIndicator(){
        this.setState({
            visibility: {
                loadingIndicator: 'visible'
            }
        })
    }

    showPage(){
        let visibility = this.state.visibility
        visibility.page = 'visible'
        this.setState({
            visibility: visibility
        })
    }

    hideLoadingIndicator(){
        this.setState({
            visibility:{
                loadingIndicator: 'hidden'
            }
        })
    }

    onChange(index,e){
       let units = this.state.units
       let value = e.target.value

       if (value === ''){
           value = 1
       }

       units[index].quantity = parseInt(value)
       this.setState({
           units: units
       })

       // set receipt payload
       this.setState({
            receipt: this.getSubscriptionValue()
       })

    }

    getSubscriptionValue(){
        let total = 0
        let units = this.state.units
        this.state.items.forEach( (item, index) => {
            total += item.price * units[index].quantity
        })
        return {
            value: total,
            delivery: 200
        }
    }

    render(){
        
        this.productRows = this.state.items.map( (value, index) => {
            return ( 
                     <SubscriptionItem title={value.name} price={value.price}
                                    key={index} onChange={(e) => this.onChange(index,e)}/>
            )
        })

        return(
            <div className='subs' style={{visibility: this.state.visibility.page}}>
                <LoadingIndicator visibility={this.state.visibility.loadingIndicator}/>
                <StickyBarTop title='My Subscriptions' noback/> 
                <div className='subs-content'>
                    <div className='subs-message'>
                    👋 Karibu, {this.state.customer.name}
                    </div>
                    <span className='subs-header'>Your items</span>
                    {this.productRows}
                </div>  
                <div className='subs-footer'>
                    <Receipt payload={this.state.receipt} />
                </div>
                <StickyBarBottom value='Checkout' onClick={this.handleClick}/> 
            </div>
        )
    }
}

export default Subscriptions
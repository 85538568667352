import React from 'react'
import '../styles/Login.css'
import BigButton from './BigButton'
import H1 from './H1'
import OTPInput from './OTPInput'
import AuthService from '../services/auth'

class Verify extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            'otp': '',
            validation: 'valid'
        }
        this.handleClick = this.handleClick.bind(this)
        this.updateOTPInput = this.updateOTPInput.bind(this)
        this.highlightOTPInput = this.highlightOTPInput.bind(this)
        this.authService = new AuthService()
        this.OTP_LENGTH = 6
    }

    async handleClick(e){
        e.preventDefault()

        let phoneNumber = window.phoneNumber
        let otp = this.state.otp
        this.authService.verify(phoneNumber, otp).then( (res) => {
            console.log(res)
            this.props.history.push('/')
    
        }).catch((err) => {
            console.error(err); 
            this.setState({
                validation: 'error'
            }) 

            this.forceUpdate()
        })
    }

    updateOTPInput(e){
        // check for max length
        if (e.target.value.length === this.OTP_LENGTH){
            this.setState({
                'otp': e.target.value
            })
        }
        if (e.target.value.length > this.OTP_LENGTH){
           e.target.value = this.state.otp
        }
        
    }

    highlightOTPInput(e){
        e.preventDefault()
        console.log('highlighted')

        this.setState({
            validation: 'valid'
        })
    }

    render(){
        return (
            <div className='Login'>
                <header className='Login-header'>
                    <H1 value='Verify'></H1>
                </header>
                <OTPInput className='Login-PhoneInput' 
                            onChange={this.updateOTPInput} 
                                onClick={this.highlightOTPInput}
                                    state={this.state.validation}/>

                <div className='Login-description'>
                TPlease enter the 6 digits code sent to (701 248 013). Didn’t receive it? Resend code.
                </div>
                <BigButton name='Continue' onClick={this.handleClick}/>
            </div>
        );
    }
    
}

export default Verify
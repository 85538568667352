import React from 'react'
import '../styles/Promocode.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

class Promocode extends React.Component {
    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className='Promocode' onClick={this.props.onClick}>
                <div className='Promocode-content'>
                    <div className='columns is-mobile'>
                        <div className='column is-3'>
                            <span className='Promocode-Icon'>
                                <FontAwesomeIcon icon={faPlus} />
                            </span>
                        </div>
                        <div className='column is-9'>
                            <span className='Promocode-Text'>
                                Add Promotion Code
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Promocode
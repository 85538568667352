import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import '../styles/Slider.css'

class Offers extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        const settings = {
            dots: true,
            arrows: false,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          };
        
        return(
            <div className='Offers'>
                <Slider {...settings}>
                    <div>
                    <img src="https://dl.airtable.com/.attachmentThumbnails/a2fbc39529af7811f17b4a9f91e3ae51/80e08d18" alt=""/>
                    </div>
                    <div>
                        <img src="https://dl.airtable.com/.attachmentThumbnails/a2fbc39529af7811f17b4a9f91e3ae51/80e08d18" alt=""/>
                    </div>
                    <div>
                        <img src="https://dl.airtable.com/.attachmentThumbnails/a2fbc39529af7811f17b4a9f91e3ae51/80e08d18" alt=""/>
                    </div>
                </Slider>
            </div>
        )
    }
}

export default Offers
import React from 'react'
import '../styles/EmptyOfferItem.css'


class EmptyOfferItem extends React.Component {

    constructor(props){
        super(props)
    }

    componentDidMount(){
        console.log('Loaded')
    }

    render(){
        return (
            <div className='EmptyOfferItem'>
                <div className='EmptyOfferItem-image shine'>

                </div>

                <div className='EmptyOfferItem-text shine'>

                </div>
                <br/>
                <div className='EmptyOfferItem-subtext shine'>

                </div>
            </div>
        );
    }
}

export default EmptyOfferItem
import React from 'react'
import '../styles/StickyMenuTop.css'
import { Link } from 'react-router-dom'
import DbService from '../services/db'
import Logo from '../images/ccg-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart} from '@fortawesome/free-solid-svg-icons'


class StickyMenuTop extends React.Component {

        constructor(props){
            super(props)
            this.dbService = new DbService()

            this.state = {
                value: this.dbService.getCartValue()
            }
        }

        async componentWillReceiveProps(nextProps){
            await new Promise(r => setTimeout(r, 50));
            this.setState({
                value: this.dbService.getCartValue()
            }, () => {
                this.forceUpdate()
            })
        }

        render(){

            return (
                <div className='StickyMenuTop'>
                    <div className="columns is-mobile">
                        <div className="column is-7 logo">
                            <div className='AppLogo'>
                                <img src={Logo} alt=""/>
                            </div>
                        </div>
                        <div className="column is-5 cart">
                            <Link to='/basket'>
                                <div className="CartCount">    
                                    <span>
                                        Ksh { this.state.value }
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            );
        }
}

export default StickyMenuTop
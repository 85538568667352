import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import StickyBarBottom from '../components/StickyBarBottom'
import EmptyContact from '../components/EmptyContact'
import ContactItem from '../components/ContactItem'
import DbService from '../services/db'
import '../styles/Contacts.css'


class Contacts extends React.Component {

    constructor(props){
        super(props)

        this.goBack = this.goBack.bind(this)
        this.navigate = this.navigate.bind(this)
        this.newContact = this.newContact.bind(this)

        this.dbService = new DbService()

        this.state = {
            contacts: null
        }
    }

    goBack(e){
        e.preventDefault()
        this.props.history.goBack()
    }

    navigate(path){
        this.props.history.push(path)
    }

    newContact(e){
        e.preventDefault()
        this.props.history.push('/contact/new')
    }

    render(){
        let contacts = this.dbService.getContacts()
        if (contacts.length != 0){

            this.state.contacts = contacts.map( (value, index ) => {    
                let link = '/contact/' + index   
                return (
                    <ContactItem contact={value} key={index} link={link} />
                )    
             })
             
            return(
                <div className='Contacts'>
                    <StickyBarTop title='My Contacts' onBackPress={this.goBack} /> 
                    <div className='Contacts-content'>
                        {this.state.contacts}
                    </div>
                    
                    <StickyBarBottom value='New Contact' onClick={this.newContact}/> 
                </div>
            )
        }
        else {
            return(
                <div className='Contacts'>
                    <StickyBarTop title='My Contacts' onBackPress={this.goBack} /> 
                    <EmptyContact onClick={() => this.navigate('/contact/new')}/> 
                </div>
            )
        }
    }
}

export default Contacts
import React from 'react'

import StickyMenuTop from '../components/StickyMenuTop'
import Slider from '../components/Slider'
import StickyMenuBottom from '../components/StickyMenuBottom'
import CategoryRow from '../components/CategoryRow'
import SupportButton from '../components/SupportButton'
import ApiService from '../services/api'
import DbService from '../services/db'

import '../styles/Home.css'

class Home extends React.Component{
    constructor(props){
        super(props)
        this.apiService = new ApiService()
        this.dbService = new DbService()
        this.state = {
            rows: null
        }
        this.categoryRows = null
        this.collection = 'categories'

        // check for affiliates
        if (this.props.match.params.code){
            let affiliate = this.props.match.params.code
            this.dbService.setAffiliate(affiliate)
        }
        else {
            this.dbService.setAffiliate(null)
        }

    }

    componentDidMount(){
        // init cache
        this.dbService.initCache()
        // init cart 
        this.dbService.initCart()
        //
        this.apiService.getCategories().then( (res) => {
            this.setState({
                rows: res
            })
        })
    }
        

    render(){
        let rows = this.state.rows
        if (rows != null ){
            let data = rows.data
            // create an array of 3s
            let results = []
            let items = []
            for (let index = 0; index < data.length; index++) {
                if (index === 0){
                    items.push(data[index])
                }
                else {

                    if (index % 3 === 0){
                        results.push(items)
                        items = []
                    }

                    items.push(data[index])
                    if (index == data.length - 1){
                        results.push(items)

                    }
                }
                
            }

            this.categoryRows = results.map((value, index ) => {
                return <CategoryRow items= {value} key={index}/>
            })

        }
        else {

                this.categoryRows = new Array(3).fill(0).map((value, index ) => {
                    return <CategoryRow empty/>
                })
        }

        this.home = [1].map( (value, index) => {
            return (
                <div>
                    <div className='header-Home'> Explore Categories</div>
                    <div className='content-Home'>
                        {this.categoryRows}
                    </div>
                </div>
                
            )
        })
        
        return (

            <div className='Home'>
                <StickyMenuTop count='0'/>
                    {this.home}    
                <StickyMenuBottom highlight='home'/>
            </div>
        );
    }
}

export default Home
import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import Promocode from '../components/Promocode'
import StickyMenuBottom from '../components/StickyMenuBottom' 
import OfferItem from '../components/OfferItem' 
import EmptyOfferItem from '../components/EmptyOfferItem'
import '../styles/Feed.css'
import ApiService from '../services/api'
import DbService from '../services/db'

class Feed extends React.Component {

    constructor(props){
        super(props)
        this.apiService = new ApiService()
        this.dbService = new DbService()
        this.state = {
            rows: null
        }

        this.LOADING_STATE_COUNT = 3
        this.collection = 'productDetails'
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount(){
        this.apiService.getFeed().then( (res) => {
            this.setState({
                rows: res
            })
        }).catch( (err) => {
            console.error(err);
        } )
    }
    
    handleClick(product){
        console.log(product)
        this.dbService.setCache(this.collection, product)
        let productDetailsPage = product.link
        console.log(productDetailsPage)
        this.props.history.push(productDetailsPage)
    }

    navigate(page){
        this.props.history.push(page)
    }

    render(){
        
        let rows = this.state.rows

        if (rows !== null){
            let data = rows.data
            this.feedRows = data.map( (value, index) => {
                return <OfferItem item= {value} onClick={ () => this.handleClick(value)}/> 
            })
        }
        else {
            let emptyRows = new Array(this.LOADING_STATE_COUNT).fill({ })
            this.feedRows = emptyRows.map( (value, index) => {
                return <EmptyOfferItem /> 
            })
        }

        return(
            <div className='Feed'>
                <StickyBarTop title='Offers' noback/> 
                <Promocode onClick={ () => this.navigate('/redeem')}/> 
                <div className='Feed-content'>
                   {this.feedRows}    
                </div>
                <StickyMenuBottom highlight='feed'/> 
            </div>
        )
    }
}

export default Feed
import React from 'react'
import StickyBarTop from '../components/StickyBarTop'
import StickyMenuBottom from '../components/StickyMenuBottom'
import OrderItem from '../components/OrderItem'
import EmptyOrderItem from '../components/EmptyOrderItem'
import '../styles/Orders.css'
import DbService from '../services/db'
import { Link } from 'react-router-dom'

class Orders extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            rows: null
        }

        this.dbService = new DbService()

        this.orderRows = null
        this.LOADING_STATE_COUNT = 8
        this.goBack = this.goBack.bind(this)
    }


    componentWillMount(){
        let orders = this.dbService.getOrders()
        this.setState({
            rows: orders
        })
    }

    goBack(e){
        this.props.history.push('/account')
    }
 
    render() {
        let rows = this.state.rows
        if (rows) {
            this.orderRows = rows.map( (value, index) => {
                return (
                    <Link to={'/orders/' + value.id} key= {value.id}>     
                        <OrderItem  date={value.created_at} 
                                    price={value.price}
                                    />
                    </Link>
                )
            })
        }
        else {
            let orders = new Array(this.LOADING_STATE_COUNT).fill({})
            this.orderRows = orders.map( (value, index) => {
                return (
                    <EmptyOrderItem />
                );
            })
        }

        return (
            <div className='Orders'>
                <StickyBarTop title='My Orders' onBackPress={this.goBack}/>
                <div className='Orders-content'>
                    {this.orderRows}
                </div>
                <StickyMenuBottom highlight='account' />
            </div>
        );
    }

}

export default Orders
import React from 'react'
import '../styles/SearchBar.css'

class SearchBar extends React.Component {
    
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='SearchBar'>
                <div className='columns is-mobile'>
                    <div className='column is-12'>
                        <input className='SearchBar-Input' type="text" placeholder='Search brands ...' onChange={this.props.onChange}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchBar
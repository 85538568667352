import React from 'react'
import '../styles/EmptyBrandItem.css'


class EmptyBrandItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){

        return(
            <div className='EmptyBrandItem'>
                <div className='EmptyBrandItem-text shine'>

                </div>
            </div>
        );
    }
}

export default EmptyBrandItem
'use strict';

const data = require('./config.json');

class Config {

    constructor(){
    }

    get(){
        return data
    }
}

export default Config
import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import '../styles/OfferItem.css'

class OfferItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return (
            <div className='OfferItem'>
                <div className='fade-in-bck'>
                    <div className='OfferItem-content'>
                        <div className='OfferItem-image'>
                            <img src={this.props.item.banner}/>
                        </div>
                        <div className='OfferItem-title' >
                            {this.props.item.title }
                        </div>
                        <div className='columns is-mobile is-gapless'>
                            <div className='column is-10'>
                                <div className='OfferItem-description'>
                                    { this.props.item.description }
                                </div>
                            </div>
                            <div className='column is-2'>
                                <div className='OfferItem-link' onClick={this.props.onClick}>
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OfferItem
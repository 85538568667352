import React from 'react'
import EmptyContactIcon from '../images/emptyContact.svg'
import '../styles/EmptyContact.css'


class EmptyContact extends React.Component {
    constructor(props){
        super(props)
    }


    render(){
        return(
            <div className='EmptyContact'>
                <div className='EmptyContact-content'>
                        <img src={EmptyContactIcon} />
                        <div className='EmptyContact-message'>
                            You haven't set any contacts yet.
                        </div>
                        <div className='EmptyContact-add'>
                            <button onClick={this.props.onClick}>
                                + Add a Contact
                            </button>
                        </div>
                    </div>
            </div>
        )
    }
}

export default EmptyContact
import React from 'react'
import '../styles/AccountItem.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AccountItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){

        return (
            <div className='AccountItem' onClick={this.props.onClick}>
                <div className='columns is-mobile'>
                    <div className='column is-6'>
                        <div className='AccountItem-header'>
                            {this.props.header}
                        </div>
                        <div className='AccountItem-content'>
                            {this.props.content}
                        </div>
                    </div>

                    <div className='column is-6'>
                        <div className='AccountItem-icon'>
                            <FontAwesomeIcon icon={this.props.icon} />
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountItem
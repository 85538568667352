import React from 'react'
import '../styles/Fallback.css'

class Fallback extends React.Component {

    render(){
        return(
            <div className='Fallback'>
                <div className='Fallback-header'>
                    ChapChapGo
                </div>
                <br/> is only available to view on mobile at the moment
            </div>
        )
    }
}

export default Fallback
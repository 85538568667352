import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import '../styles/AddressItem.css'
import { Link } from 'react-router-dom'


class AddressItem extends React.Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='AddressItem'>
                <Link to={this.props.link} className='AddressItem-Link'>
                    <div className='AddressItem-content'>
                        <div className='columns is-mobile'>
                            <div className='column is-1'>
                                <div className='AddressItem-icon'>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </div>
                            </div>
                            <div className='column is-9'>
                                <div className='AddressItem-description'>
                                    {this.props.address.description}
                                </div>
                                <div className='AddressItem-region'>
                                    {this.props.address.region}
                                </div>
                            </div>
                            <div className='column is-2'>
                            <div className='AddressItem-icon'>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}

export default AddressItem
import React from 'react'
import '../styles/StickyBarBottom.css'

class StickyBarBottom extends React.Component{

    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className='StickyBarBottom'>
                <button className='StickyBarBottom-Add' onClick={this.props.onClick}>
                    {this.props.value}
                </button>
            </div>
        );
    }
}


export default StickyBarBottom
import React from 'react'
import CategoryItem from '../components/CategoryItem'
import '../styles/CategoryRow.css'

class CategoryRow extends React.Component {

    constructor(props){
        super(props)
        this.categoryItems= null
    }

    render(){

        if (!this.props.empty){
            this.categoryItems = this.props.items.map((value, index) => {
                return (
                    <div className='column is-4' key={index}>
                        <CategoryItem url={value['name_encoded']} name={value.name}
                                backgroundImage={value['image']}/>
                    </div>
                );
            })

        }
        else {

            this.categoryItems = new Array(3).fill(0).map((value, index) => {
                return (
                    <div className='column is-4' key={index}>
                        <CategoryItem empty/>
                    </div>
                );
            })
        }

        return (
            <div className='CategoryRow'>
            <div className='columns is-mobile is-gapless'>
               {this.categoryItems}
            </div>
        </div>
        );
    }
}

export default CategoryRow
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import '../styles/OrderItem.css'

class OrderItem extends React.Component {

    constructor(props){
        super(props)
        this.humanize = this.humanize.bind(this)
    }

    humanize(ts){
        return new Date(ts).toLocaleDateString()
    }

    render(){
        return(
            <div className='OrderItem'>
                <div className='fade-in-bck'>
                <div className='columns is-mobile'>
                    <div className='column is-5'>
                        <div className='OrderItem-Reference'>
                            {this.humanize(this.props.date)}
                        </div>
                    </div>
                    <div className='column is-5'>
                        <div className='OrderItem-Price'>
                            Ksh {this.props.price}
                        </div>
                    </div>
                    <div className='column is-2'>
                        <div className='OrderItem-Arrow'>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
    
}

export default OrderItem